import React from "react";
import img16 from "../img/blog/blog-16.jpg";
import img17 from "../img/blog/blog-17.jpg";
import img18 from "../img/blog/blog-18.jpg";
import img19 from "../img/blog/blog-19.jpg";
import img20 from "../img/blog/blog-20.jpg";
import img21 from "../img/blog/blog-21.jpg";
import img22 from "../img/blog/blog-22.jpg";
import img23 from "../img/blog/blog-23.jpg";
import img24 from "../img/blog/blog-24.jpg";
import img25 from "../img/blog/blog-25.jpg";
const Masonry1 = () => {
  return (
    <>
      <div className="blog-area sec-p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="masonry-blog card-columns">
                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img16} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">Industrial Revolution Factory</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>

                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img17} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">january 21, 2010</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">world class technology</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>

                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img18} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>

                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img19} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>

                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img20} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>

                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img21} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>
                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img22} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>
                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img23} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>
                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img24} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>
                <div className="masonry-single-blog card">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img25} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-50"></div>
          <div
            className="tractour-pagination"
            aria-label="page navigation example"
          >
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="fa fa-long-arrow-left"></i>
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="fa fa-long-arrow-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Masonry1;
