import React from "react";
import details from "../img/blog/blog-detail.jpg";
import content from "../img/blog/blog-content-bg.jpg";
import img4 from "../img/blog/blog-4.jpg";
import img5 from "../img/blog/blog-5.jpg";
import img6 from "../img/blog/blog-6.jpg";
import comment1 from "../img/blog/comments1.png";
import comment2 from "../img/blog/comments2.png";

const BlogDetails1 = () => {
  return (
    <>
      <div className="blog-area sec-p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="blog-details">
                <div className="img-date-wrape">
                  <img src={details} alt="blog-img" className="img-fluid" />
                  <div className="blog-date">December 10, 2019</div>
                </div>
                <div className="blog-content">
                  <h3>
                    <a href="#">business groth in app</a>
                  </h3>
                  <span></span>
                  <p>
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system, and expound the
                    actual teachings of the great explorer of the truth, the
                    master-builder of human happiness. No one rejects, dislikes,
                    or avoids pleasure itself, because it is pleasure, but
                    because those who do not know how to pursue pleasure
                    rationally encounter consequences that are extremely
                    painful. Nor again is there anyone who loves or pursues or
                    desires to obtain pain of itself, because it is pain, but
                    because occasionally circumstances occur in which toil and
                    pain can procure him some great pleasure. To take a trivial
                    example, which of us ever undertakes laborious physical
                    exercise, except to
                  </p>
                  <blockquote className="blockquote">
                    <p>
                      industry.has been the industry's standard dummy text ever
                      since the 1500s, when an unk nown printer industry.has
                      been the industry's standard dummy text
                    </p>
                  </blockquote>
                  <p>
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially
                    unchanged. It was popularised in industry. Lorem Ipsum has
                    been the industry'sindustry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to
                    make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in
                    industry.industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in industry.
                  </p>
                  <div className="blog-post-img mt-30 mb-30">
                    <img src={content} alt="blog" className="img-fluid" />
                  </div>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velitSed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas
                  </p>
                </div>
                <div className="post-tag-share-area">
                  <ul className="post-tag">
                    <li>
                      <strong>Tags :</strong>
                    </li>
                    <li>
                      <a href="#">Corporate,</a>
                    </li>
                    <li>
                      <a href="#">Business,</a>
                    </li>
                    <li>
                      <a href="#">Psd,</a>
                    </li>
                    <li>
                      <a href="#">Themeforest,</a>
                    </li>
                  </ul>
                  <div className="blog-share-icon">
                    <span>Share: </span>
                    <a href="#">
                      <i className="fa fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="post-heading">
                <h3>related blog</h3>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img4} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img5} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="single-blog">
                    <div className="img-date-wrape">
                      <img src={img6} alt="blog-img" className="img-fluid" />
                      <div className="blog-date">December 10, 2019</div>
                    </div>
                    <div className="blog-content">
                      <h3>
                        <a href="#">our best information for you</a>
                      </h3>
                      <span></span>
                      <p>
                        industry. Lorem Ipsum has been the ever a industry
                        standard dummy text ever since is the 1500 an unknown
                        printer took a text ever since is the 1500
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="recent-comments-area">
                <div className="post-heading">
                  <h3>recent comments</h3>
                </div>
                <div className="latest-comments">
                  <ul>
                    <li>
                      <div className="comments-box">
                        <div className="comments-avatar">
                          <img src={comment1} alt="blog-img" />
                        </div>
                        <div className="comments-text">
                          <div className="avatar-name-date">
                            <h5>david mors</h5>
                            <span>Jan 08 2020</span>
                          </div>
                          <p>
                            industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since t he 1500s, when an
                            unknown printer took a galley of type and
                          </p>
                          <a href="#">Reply</a>
                        </div>
                      </div>
                      <ul className="comments-reply">
                        <li>
                          <div className="comments-box">
                            <div className="comments-avatar">
                              <img src={comment2} alt="blog-img" />
                            </div>
                            <div className="comments-text">
                              <div className="avatar-name-date">
                                <h5>tractour industries</h5>
                                <span>Jan 08 2020</span>
                              </div>
                              <p>
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the when
                              </p>
                              <a href="#">
                                Reply
                                <i className="fa fa-angle-double-right"></i>
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="comments-box">
                        <div className="comments-avatar">
                          <img src={comment1} alt="blog-img" />
                        </div>
                        <div className="comments-text">
                          <div className="avatar-name-date">
                            <h5>david mors</h5>
                            <span>Jan 08 2020</span>
                          </div>
                          <p>
                            industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since t he 1500s, when an
                            unknown printer took a galley of type and
                          </p>
                          <a href="#">Reply</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="post-comments-form-area">
                <div className="post-heading">
                  <h3>leave a reply</h3>
                  <p>
                    Your email address will not be published. Required fields
                    are marked *
                  </p>
                </div>
                <form action="#" className="post-comments-form">
                  <div className="row">
                    <div className="col-xl-12">
                      <textarea
                        name="comments"
                        className="form-control"
                        cols="30"
                        rows="10"
                        placeholder="Your Comments"
                      ></textarea>
                    </div>
                    <div className="col-xl-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-xl-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="col-xl-4">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="E-mail"
                      />
                    </div>
                    <div className="col-xl-12">
                      <button className="btn btn-type-3" type="submit">
                        Post Comments
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails1;
