import React from "react";
import secimg from "../img/separ-logo.png";
import axios from "axios";
import { apiURL, imageURL } from "../constant/apiURL";
import LoadingIndicator from "react-loading-indicator";
import { Link } from "react-router-dom";
const References = () => {
  const [referencesData, setReferencesData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

 console.log(referencesData)

  const centerDiv = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const divStyle = {
    color: "blue",
    //backgroundColor: "red",

    borderRadius: "5px",
  };

  const getReferences = async () => {
    try {
      setIsLoading(true);
     const result=await axios.get(`${apiURL}/reference/getAllReferences`)
     //setReferencesData(result.data.data)
    let array=[]
    for (var i=0 ; i <12;i++){
        array.push(result.data.data[0])
    }
    setReferencesData(array)
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("erreur is   ", err);
      alert("Erreur au niveau de serveur !");
    }
  };

  React.useEffect(() => {
    getReferences();
  }, []);
  return (
    <>
      {isLoading ? (
        <div style={centerDiv}>
          <div style={divStyle}>
            <LoadingIndicator />
          </div>
        </div>
      ) : (
        <div className="project-area bg-color3 sec-p-100 text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mx-auto">
                <div className="section-title text-center mb-70">
                  <h2>Nos références :</h2>
                  <span>
                    <img src={secimg} alt="separetor" />
                  </span>

                  <p>
                    La société HSI dispose aujourd’hui de plusieurs références
                    de taille en Tunisie, témoignant de la qualité de ses
                    produits, de la confiance de ses clients en ses produits et
                    services et de son savoir-faire, dont notamment :
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="project-wraper">
            <div className="container-fluid p-0">
              <div className="row custom-row">
        {referencesData.map((reference,index)=>{
           
         return(
                <div className="single-project">
               <img src={`${imageURL}/${reference.image}`} alt="project-img" className="img-fluid" /> 
               
          
                <div className="project-hvr">
                   
                  <Link to="">
                
                    <h3> {reference.name}</h3>
                 
                  </Link>
             
                  <Link className="btn" to="">
                    <i className="fa fa-angle-right"></i>
                  </Link>
                </div>
              </div>  
            ) 
        })}
            {/*   <div className="single-project">
                  <img src="http://37.59.206.4:5555/photos/1722338726182-1721732207079-gremda.jpg" alt="project-img" className="img-fluid" />
                  <div className="project-hvr">
                    <Link to="">
                      <h3>Central électrique à cycle combiné (1000 TONES) :</h3>
                    </Link>
                    <Link className="btn" to="">
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default References;
